@font-face {
	font-family: 'Billabong';
	src: url('./theme/fonts/Billabong.ttf') format('truetype');
}

@font-face {
	font-family: 'Prestige';
	src: url('./theme/fonts/Prestige.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css?family=Dancing+Script|Quattrocento&display=swap');

html {
	min-height: 100% !important;
}

body,
#root,
.main {
	background: #fff;
	/* background: #d1d3d4; */
	position: relative;
	margin: 0;
	padding: 0;
	min-height: 100vh;
	height: auto;
	font-family: 'Montserrat', sans-serif;
	/* darkGrey */
	/* color: #414042 !important; */
	font-size: 16px;
	line-height: 1.5;
	font-weight: 400;
}
